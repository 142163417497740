.imageBox {
  border: 1px solid;
  width: 130px;
  height: 130px;
  margin: 30px 30px 30px 10px;
  position: relative;
}
.imageGroup {
  float: left;
  width: 100%;
  padding-left: 30px;
}
.imageGroup .AddButton {
  border: 1px solid lightgray;
  background-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  top: 44px;
}

.imageGroup .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth {
  opacity: 0 !important;
}
.deltBtn {
  position: relative;
  top: -9px;
  right: -110px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageBox input {
  background-color: red;
  height: 118px;
  width: 100%;
  z-index: 999;
  opacity: 0;
  position: absolute;
  top: -8px;
}
.imageBox img {
  width: 100%;
  height: 100%;
  position: absolute;
}

img.modal_img_plus {
  width: 50px;
  height: 50px;
  top: 28%;
  left: 28%;
}
.plusIcon {
  position: absolute;
  top: 40%;
  left: 39%;
}
.imagebox input::after {
  opacity: 0;
  display: none;
}
.imagebox input::after {
  opacity: 0;
  display: none;
}
