@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.color_box {
    width: 25px;
    min-height: 25px;
    border-radius: 5px;
    border: 0.32px solid;
}
.color_box.add {
    text-align: center;
    font-size: 23px;
    cursor: pointer;
}
.login_page {
    margin: 0 auto;
    width: 430px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    border: 0;
    padding: 20px;
    background-color: #0000007a;
    border-radius: 10px;
}
.img_doc{
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.cross_img {
    position: absolute;
    right: 43px;
    font-size: 31px;
    font-weight: 600;
    cursor: pointer;
}
.cross_img2 {
    top: 9px;
    right: 10px;
}
span.edit_icon.doc_icon {
    position: relative;
    left: 282px;
    top: 12px;
    background-color: black;
    color: white;
    padding: 8px 6px;
    border-radius: 100%;
}
.login label {
    color: #fff;
}

.reception_box {
    border: 1px solid black;
    border-radius: 11px;
    padding: 10px;
}
.font-bold {
    font-weight: bold;
}
img.signature_img {
    max-width: 200px;
    max-height: 100px;
    object-fit: cover;
}
span.box_text {
    border: 1px solid black;
    padding: 1px 12px;
    margin-left: 10px;
}
button.button_opt {
    padding: 20px 70px;
    border: 2px solid #9c34b2;
    background-color: #d1bbd5;
    border-radius: 5px;
    color: #9c34b2;
    font-size: 20px;
    max-width: 290px;
}

.chat_box {
    min-height: 452px;
    border: none;
    border-radius: 6px;
    max-height: 452px;
    overflow: auto;
    overflow-x: hidden;
    background-color: white;
    box-shadow: 3px 7px 5px #ebe0e0;
}

.single_chat img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100%;
    padding: 3px;
    border: 2px solid purple;
    margin-right: 10px;
}

p.error_message {
    margin-bottom: 0px;
    padding: 0px 22px;
    font-size: 11px;
    color: red;
    margin-top: -7px;
}


.single_chat:hover {
    background-color: rgb(231, 84, 231);
    color: white;
    cursor: pointer;
}

.single_chat.active {
    background-color: purple;
    color: white;
    cursor: pointer;
}

.chat_messages p {
    margin: 10px 30px;
    padding: 10px 10px;
    border-radius: 14px;
}
.receive {
    background-color: #d3caca;
    color: #0e0d0db0;
    width: fit-content;
    text-align: left;
}

.all_chats {
    margin: 8px 2px;
}
.single_chat p {
    margin-bottom: 0rem !important;
    padding-bottom: 0px;
}
.chat_header p{
    margin-bottom: 0px !important;
}
.single_chat {
    padding: 10px;
}

.chat_header img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
    margin: 10px;
}

.chat_header hr {
    margin: 0px;
}

.chat_footer button {
    padding: 11px 12px;
    border-radius: 4px;
    border: none;
    background-color: #9830b0;
    color: white;
}

.chat_messages {
    min-height: 266px;
    overflow: auto;
    overflow-x: hidden;
    max-height: 275px;
}

.chat_footer input {
    margin: 6px 21px;
    width: 82%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #00000057;
}

button.button_opt:hover {
    padding: 20px 70px;
    border: 2px solid #9c34b2;
    background-color: #9c34b2;
    border-radius: 5px;
    color: white;
}
.for_center{
    text-align: right;
}

.margin-top{
    margin-top:25px;
}

.MuiSelect-selectMenu .MuiChip-root {
    margin-bottom: 8px;
    margin-right: 9px;
}

.MuiSelect-selectMenu {
    white-space: normal !important;
}


.MuiFormControl-root {
    width: 100%!important;
}


.makeStyles-formControl-105 {
    margin: 8px;
    max-width: 100%!important;
    min-width: 120px;
}

.css-13cymwt-control{
    border: 0!important;
    border-bottom: 1px solid gray!important;
    border-radius: 0!important;
}

.color_box.rect {
    width: 40px;
    min-height: 19px !important;
    margin-top: 3px;
}

.login .form-group input {
    border-radius: 50px;
}

.login button.btn.btn-primary {
    outline: 0;
    border: 0;
    border-radius: 50px;
    padding: 10px 30px;
    margin: 20px 0 20px 0;
    float: none;
    width: 100%;
    max-width: 150px;
}

.login_page h2 {
    text-align: center;
    font-weight: 600;
    color: #fff;
}

.password_wrap {
    position: relative;
}

.password_wrap svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.full-bg {
    width: 100%;
    /* float: left; */
}

.full-bg img {
    width: 100%;
    position: fixed;
}

button.icon_boundry{
    background-color: rgb(153, 153, 153);
    margin-left: 5px;
    margin-right: 5px;
}
/* 19 feb 2020 */

body .MuiDialog-paperWidthSm {
    max-width: 486px;
    width: 100%;
}

body .MuiListItem-button {
    display: flex;
    /* width: 100%; */
}

body .MuiListItem-button .MuiListItemText-inset {
    padding-left: 0;
}

body .MuiFormControl-root {
    min-width: 100px;
}

.issue_img, .issue_img1, .issue_img2 {
    width: 100%;
    max-width: 196px;
    max-height: 164px;
    object-fit: contain;
}

.modal_img {
    /* margin-top: 18px; */
    width: 100%;
    /* max-width: 164px; */
    max-height: 146px;
    object-fit: contain;
    object-position: left center;
}

.tick {
    background: #008ade;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: table;
    margin: 0 auto;
}

.tick .tick_icon {
    font-size: 50px;
    color: #fff;
    margin: 15px 0 0px 15px;
}

.loader-text-sec {
    transform: translateY(50%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    box-shadow: 0px 2px 11px #ccc;
    padding: 13px;
    width: 50%;
    margin: 0 auto;
    height: 230px;
    padding-top: 30px;
}

.loader-text {
    text-align: center;
    padding-top: 40px;
}

.loader-text h2 {
    color: #008ade;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
}

.loader_class {
    display: table !important;
    margin: 0 auto !important;
    /* padding-bottom: 42px; */
    width: 80px !important;
    color: #088ddf !important;
}

body .verify-full {
    width: 100%;
    background-color: #fff !important;
}

.issue_action input[type="file"] {
    display: none;
}

.custom-file-upload {
    overflow: hidden;
    border: 1px solid;
    width: 150px;
    height: 150px;
    float: left;
    cursor: pointer;
}
label.custom-file-upload {
    position: relative;
    margin: 0;
}

label.custom-file-upload svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50% !important;
    transform: translateY(-50%);
}

.upload_wrap {
    display: flex;
    align-items: center;
}

.upload_wrap div~div {
    order: 1;
}

.upload_wrap div {
    order: 2;
    margin: 0 10px 0 0;
    position: relative;
}

.upload_wrap div img.issue_img {
    max-width: none;
    max-height: none;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 150px;
    height: 150px;
}

.upload_wrap div svg {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}
h6.header_platform {
    color: #ffffff;
    padding: 8px 15px;
    /* font-weight: 800; */
}
.header_platform button {
    background-color: #ffffff;
    float: right;
    padding: 4px 4px;
}
.MuiListItem-container {
    border-bottom: 1px solid #c1c0c0;
}

button.help_icon_device, button.issue_icon_device{
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
    margin: 0 0px 0 10px;
}
button.edit_icon_device {
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
    margin: 0 10px;
}
button.delete_icon_device {
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
}
.table_class_width td.MuiTableCell-root {
    max-width: 270px;
}
.MuiGrid-container img.user_profile {
    margin: 0 auto;
    display: block;
    border: 2px solid #a25bb5;
    padding: 0px 0 0 0px;
    border-radius: 100px;
}
h5.header_help.MuiTypography-h5 {
    font-size: 16px;
    background-color: #ebebeb;
    padding: 8px 10px;
    position: relative;
}
h5.header_help.MuiTypography-h5 button {
    float: right;
    position: absolute;
    top: -4px;
    right: 4px;

}
.help_delete_icon .MuiIconButton-label svg.MuiSvgIcon-root {
    width: 20px;
}
.issue_images{
    text-align: center;
}
.issue_images img.issue_img1 {
    border: 2px solid #a75db8;
    border-radius: 100%;
    height: 185px;
    max-height: none;
    margin: 0 10px 0 0;
}
.issue_images img.issue_img2 {
    border: 2px solid #a75db8;
    border-radius: 100%;
    height: 185px;
    max-height: none;
}

/*Added 26-May-2020*/


.helpCountData span {
    margin: 0 10px 0 0;
}
.MuiInputAdornment-marginDense p {
    font-size: 20px;
    margin: 0 0 6px;
    font-weight: 700;
    color: #9931b1;
}
#root .helpCountData {
    margin: 12px 0 0;
}

img.excel_icon, img.csv_icon {
    width: 20px;
}

img.excel_icon{
    margin: 0px 10px;
}

.parent_search_export .right .MuiInputBase-root {
    margin-top: 0;
}
.parent_search_export .right label#standard-search-label {
    top: -20px;
}
.parent_search_export .right {
    order: 2;
}
.parent_search_export {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.parent_search_export .right input {
    width: 250px;
}
.chat_messages .receive, .chat_messages .sent {
    width: auto;
    box-sizing: border-box;
    display: inline-block;
    max-width: 80%;
}
.sent {
    background: #800080;
    color: #fff;
}
.headings p {
    background-color: #9830b0;
    border-radius: 8px;
    padding: 10px 19px;
    color: white;
}

.initial p {
    padding: 31px;
    font-size: 16px;
}

.headings span {
    float: right;
    cursor: pointer;
}

.heading-arrange {
    font-weight: 700;
    font-size: 20px;
}