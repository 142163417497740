@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.color_box {
    width: 25px;
    min-height: 25px;
    border-radius: 5px;
    border: 0.32px solid;
}
.color_box.add {
    text-align: center;
    font-size: 23px;
    cursor: pointer;
}
.login_page {
    margin: 0 auto;
    width: 430px;
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    right: 0;
    border: 0;
    padding: 20px;
    background-color: #0000007a;
    border-radius: 10px;
}
.img_doc{
    width: 300px;
    height: 200px;
    object-fit: cover;
}

.cross_img {
    position: absolute;
    right: 43px;
    font-size: 31px;
    font-weight: 600;
    cursor: pointer;
}
.cross_img2 {
    top: 9px;
    right: 10px;
}
span.edit_icon.doc_icon {
    position: relative;
    left: 282px;
    top: 12px;
    background-color: black;
    color: white;
    padding: 8px 6px;
    border-radius: 100%;
}
.login label {
    color: #fff;
}

.reception_box {
    border: 1px solid black;
    border-radius: 11px;
    padding: 10px;
}
.font-bold {
    font-weight: bold;
}
img.signature_img {
    max-width: 200px;
    max-height: 100px;
    object-fit: cover;
}
span.box_text {
    border: 1px solid black;
    padding: 1px 12px;
    margin-left: 10px;
}
button.button_opt {
    padding: 20px 70px;
    border: 2px solid #9c34b2;
    background-color: #d1bbd5;
    border-radius: 5px;
    color: #9c34b2;
    font-size: 20px;
    max-width: 290px;
}

.chat_box {
    min-height: 452px;
    border: none;
    border-radius: 6px;
    max-height: 452px;
    overflow: auto;
    overflow-x: hidden;
    background-color: white;
    box-shadow: 3px 7px 5px #ebe0e0;
}

.single_chat img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100%;
    padding: 3px;
    border: 2px solid purple;
    margin-right: 10px;
}

p.error_message {
    margin-bottom: 0px;
    padding: 0px 22px;
    font-size: 11px;
    color: red;
    margin-top: -7px;
}


.single_chat:hover {
    background-color: rgb(231, 84, 231);
    color: white;
    cursor: pointer;
}

.single_chat.active {
    background-color: purple;
    color: white;
    cursor: pointer;
}

.chat_messages p {
    margin: 10px 30px;
    padding: 10px 10px;
    border-radius: 14px;
}
.receive {
    background-color: #d3caca;
    color: #0e0d0db0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: left;
}

.all_chats {
    margin: 8px 2px;
}
.single_chat p {
    margin-bottom: 0rem !important;
    padding-bottom: 0px;
}
.chat_header p{
    margin-bottom: 0px !important;
}
.single_chat {
    padding: 10px;
}

.chat_header img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
    margin: 10px;
}

.chat_header hr {
    margin: 0px;
}

.chat_footer button {
    padding: 11px 12px;
    border-radius: 4px;
    border: none;
    background-color: #9830b0;
    color: white;
}

.chat_messages {
    min-height: 266px;
    overflow: auto;
    overflow-x: hidden;
    max-height: 275px;
}

.chat_footer input {
    margin: 6px 21px;
    width: 82%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #00000057;
}

button.button_opt:hover {
    padding: 20px 70px;
    border: 2px solid #9c34b2;
    background-color: #9c34b2;
    border-radius: 5px;
    color: white;
}
.for_center{
    text-align: right;
}

.margin-top{
    margin-top:25px;
}

.MuiSelect-selectMenu .MuiChip-root {
    margin-bottom: 8px;
    margin-right: 9px;
}

.MuiSelect-selectMenu {
    white-space: normal !important;
}


.MuiFormControl-root {
    width: 100%!important;
}


.makeStyles-formControl-105 {
    margin: 8px;
    max-width: 100%!important;
    min-width: 120px;
}

.css-13cymwt-control{
    border: 0!important;
    border-bottom: 1px solid gray!important;
    border-radius: 0!important;
}

.color_box.rect {
    width: 40px;
    min-height: 19px !important;
    margin-top: 3px;
}

.login .form-group input {
    border-radius: 50px;
}

.login button.btn.btn-primary {
    outline: 0;
    border: 0;
    border-radius: 50px;
    padding: 10px 30px;
    margin: 20px 0 20px 0;
    float: none;
    width: 100%;
    max-width: 150px;
}

.login_page h2 {
    text-align: center;
    font-weight: 600;
    color: #fff;
}

.password_wrap {
    position: relative;
}

.password_wrap svg {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.full-bg {
    width: 100%;
    /* float: left; */
}

.full-bg img {
    width: 100%;
    position: fixed;
}

button.icon_boundry{
    background-color: rgb(153, 153, 153);
    margin-left: 5px;
    margin-right: 5px;
}
/* 19 feb 2020 */

body .MuiDialog-paperWidthSm {
    max-width: 486px;
    width: 100%;
}

body .MuiListItem-button {
    display: flex;
    /* width: 100%; */
}

body .MuiListItem-button .MuiListItemText-inset {
    padding-left: 0;
}

body .MuiFormControl-root {
    min-width: 100px;
}

.issue_img, .issue_img1, .issue_img2 {
    width: 100%;
    max-width: 196px;
    max-height: 164px;
    object-fit: contain;
}

.modal_img {
    /* margin-top: 18px; */
    width: 100%;
    /* max-width: 164px; */
    max-height: 146px;
    object-fit: contain;
    object-position: left center;
}

.tick {
    background: #008ade;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: table;
    margin: 0 auto;
}

.tick .tick_icon {
    font-size: 50px;
    color: #fff;
    margin: 15px 0 0px 15px;
}

.loader-text-sec {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    box-shadow: 0px 2px 11px #ccc;
    padding: 13px;
    width: 50%;
    margin: 0 auto;
    height: 230px;
    padding-top: 30px;
}

.loader-text {
    text-align: center;
    padding-top: 40px;
}

.loader-text h2 {
    color: #008ade;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
}

.loader_class {
    display: table !important;
    margin: 0 auto !important;
    /* padding-bottom: 42px; */
    width: 80px !important;
    color: #088ddf !important;
}

body .verify-full {
    width: 100%;
    background-color: #fff !important;
}

.issue_action input[type="file"] {
    display: none;
}

.custom-file-upload {
    overflow: hidden;
    border: 1px solid;
    width: 150px;
    height: 150px;
    float: left;
    cursor: pointer;
}
label.custom-file-upload {
    position: relative;
    margin: 0;
}

label.custom-file-upload svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.upload_wrap {
    display: flex;
    align-items: center;
}

.upload_wrap div~div {
    order: 1;
}

.upload_wrap div {
    order: 2;
    margin: 0 10px 0 0;
    position: relative;
}

.upload_wrap div img.issue_img {
    max-width: none;
    max-height: none;
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 150px;
    height: 150px;
}

.upload_wrap div svg {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}
h6.header_platform {
    color: #ffffff;
    padding: 8px 15px;
    /* font-weight: 800; */
}
.header_platform button {
    background-color: #ffffff;
    float: right;
    padding: 4px 4px;
}
.MuiListItem-container {
    border-bottom: 1px solid #c1c0c0;
}

button.help_icon_device, button.issue_icon_device{
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
    margin: 0 0px 0 10px;
}
button.edit_icon_device {
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
    margin: 0 10px;
}
button.delete_icon_device {
    background-color: #e4e2e2;
    border-radius: 2px;
    padding: 5px 5px;
}
.table_class_width td.MuiTableCell-root {
    max-width: 270px;
}
.MuiGrid-container img.user_profile {
    margin: 0 auto;
    display: block;
    border: 2px solid #a25bb5;
    padding: 0px 0 0 0px;
    border-radius: 100px;
}
h5.header_help.MuiTypography-h5 {
    font-size: 16px;
    background-color: #ebebeb;
    padding: 8px 10px;
    position: relative;
}
h5.header_help.MuiTypography-h5 button {
    float: right;
    position: absolute;
    top: -4px;
    right: 4px;

}
.help_delete_icon .MuiIconButton-label svg.MuiSvgIcon-root {
    width: 20px;
}
.issue_images{
    text-align: center;
}
.issue_images img.issue_img1 {
    border: 2px solid #a75db8;
    border-radius: 100%;
    height: 185px;
    max-height: none;
    margin: 0 10px 0 0;
}
.issue_images img.issue_img2 {
    border: 2px solid #a75db8;
    border-radius: 100%;
    height: 185px;
    max-height: none;
}

/*Added 26-May-2020*/


.helpCountData span {
    margin: 0 10px 0 0;
}
.MuiInputAdornment-marginDense p {
    font-size: 20px;
    margin: 0 0 6px;
    font-weight: 700;
    color: #9931b1;
}
#root .helpCountData {
    margin: 12px 0 0;
}

img.excel_icon, img.csv_icon {
    width: 20px;
}

img.excel_icon{
    margin: 0px 10px;
}

.parent_search_export .right .MuiInputBase-root {
    margin-top: 0;
}
.parent_search_export .right label#standard-search-label {
    top: -20px;
}
.parent_search_export .right {
    order: 2;
}
.parent_search_export {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.parent_search_export .right input {
    width: 250px;
}
.chat_messages .receive, .chat_messages .sent {
    width: auto;
    box-sizing: border-box;
    display: inline-block;
    max-width: 80%;
}
.sent {
    background: #800080;
    color: #fff;
}
.headings p {
    background-color: #9830b0;
    border-radius: 8px;
    padding: 10px 19px;
    color: white;
}

.initial p {
    padding: 31px;
    font-size: 16px;
}

.headings span {
    float: right;
    cursor: pointer;
}

.heading-arrange {
    font-weight: 700;
    font-size: 20px;
}
.imageBox {
  border: 1px solid;
  width: 130px;
  height: 130px;
  margin: 30px 30px 30px 10px;
  position: relative;
}
.imageGroup {
  float: left;
  width: 100%;
  padding-left: 30px;
}
.imageGroup .AddButton {
  border: 1px solid lightgray;
  background-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  top: 44px;
}

.imageGroup .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth {
  opacity: 0 !important;
}
.deltBtn {
  position: relative;
  top: -9px;
  right: -110px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageBox input {
  background-color: red;
  height: 118px;
  width: 100%;
  z-index: 999;
  opacity: 0;
  position: absolute;
  top: -8px;
}
.imageBox img {
  width: 100%;
  height: 100%;
  position: absolute;
}

img.modal_img_plus {
  width: 50px;
  height: 50px;
  top: 28%;
  left: 28%;
}
.plusIcon {
  position: absolute;
  top: 40%;
  left: 39%;
}
.imagebox input::after {
  opacity: 0;
  display: none;
}
.imagebox input::after {
  opacity: 0;
  display: none;
}

/*!

 =========================================================
 * Material Dashboard React - v1.8.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  .badge-primary-background-color: #9c27b0;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  transform: translateY(-13%);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}

